import Link from '@mui/material/Link';
import Image from 'next/image';
import { CmsPartnerLogo } from '@/services/cms/models/CmsPartnerLogo';

export interface PartnerLogoProps {
    partnerLogo: CmsPartnerLogo;
}

export const PartnerLogo = ({ partnerLogo }: PartnerLogoProps) => {
    const image = partnerLogo.image
        ? <Image src={partnerLogo.image.link} alt={partnerLogo.image.title} height={partnerLogo.image.height} width={partnerLogo.image.width} />
        : null;

    if (partnerLogo.link) {
        return (
            <Link
                href={partnerLogo.link.url}
                title={partnerLogo.link.title}
                sx={{ display: 'flex', alignItems: 'center' }}
                target={partnerLogo.link.openInNew ? '_blank' : undefined}
            >
                {image || partnerLogo.link.title}
            </Link>
        );
    }

    return image;
};
