'use client';

import { StackProps } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { mergeSx } from '@jucy-ui/appearance';
import { CmsPartnerLogo } from '@/services/cms/models/CmsPartnerLogo';
import { PartnerLogo } from '@/components/PartnerLogo';

export interface FooterPartnersProps extends StackProps {
    partnerLogos: CmsPartnerLogo[];
}

export const FooterPartnerLogos = ({ title, partnerLogos, direction = { xs: 'column', sm: 'row' }, gap = 3, sx, ...props }: FooterPartnersProps) => (
    <Stack
        direction={direction}
        {...props}
        sx={mergeSx({ alignItems: 'center', justifyContent: { xs: 'center', sm: 'left', md: 'right' }, gap: gap  }, sx)}
    >
        {title ? <Typography sx={{ textAlign: { xs: 'center', md: 'left' } }} variant="title1">{title}</Typography> : null}
        <Stack
            direction={'row'}
            sx={{
                flexDirection: { xs: 'column', sm: 'row' },
                flexWrap: 'wrap',
                gap: gap,
                justifyContent: 'center'
            }}
        >
            {partnerLogos.map((partnerLogo) => (
                <PartnerLogo key={partnerLogo.id} partnerLogo={partnerLogo} />
            ))}
        </Stack>
    </Stack>
);
